import React from "react"
import { Row } from "react-bootstrap"
import { Link } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Footer = () => (
  <Row className="d-flex align-items-center">
    <div style={{ position: `absolute`, bottom: 30, marginLeft: `auto`, marginRight: `auto`, left: 0, right: 0, width: `80%`, display: `block` }}>
        <div style={{ textAlign: `center`, fontSize: `0.8em` }}>
          Copyright © {new Date().getFullYear()} Babblestack Pty Ltd
        <br />
          <Link to="/terms/">Terms & Conditions</Link> | <Link to="/privacy/">Privacy Policy</Link></div>
    </div>
  </Row>
)

export default Footer