import React from "react"
import ReactFullpage from '@fullpage/react-fullpage';
import { StaticQuery, graphql } from "gatsby"
import { Modal, Button, Form } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageMain from "../components/page-main"
import ButtonSignup from "../components/button-signup";
import ButtonDemo from "../components/button-demo"

export default class IndexPage extends React.Component {
  constructor (props, context) {
    super(props, context);

    this.state = {
      show: false,
      buttonColor: "inherit",
      buttonClicked: "Schedule a Demo",
      showThanks: false,
      name: "",
      title: "",
      email: "",
      errors: {
        email: "",
      },
    }

    this.toggleModal = this.toggleModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.encode = this.encode.bind(this);
    this.validate = this.validate.bind(this);
  }

  toggleModal(btnType) {
    if (btnType !== "request") {
      this.setState({
        show: true,
        buttonColor: "default",
        buttonClicked: "Schedule a Demo",
        showThanks: false,
      });
    } else {
      this.setState({
        show: true,
        buttonColor: "default",
        buttonClicked: "Schedule a Demo",
        showThanks: false,
      });
    }
  }

  encode (data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  validate(values) {
    let errors = {};
    let isError = false;
    if (!values.email) {
      errors.email = 'Email address is required';
      isError = true;
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
      isError = true;
    }
    if (!values.name) {
      errors.name = 'Name is required';
      isError = true;
    }
    if (!values.title) {
      errors.title = 'Title is required';
      isError = true;
    }
    if (isError) {
      this.setState({
        errors: errors,
      })
    }
    return isError;
  }

  handleSubmit(e) {
    const isError = this.validate(this.state);
    if (isError) {
      e.preventDefault();
      return;
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({ 
        "form-name": "contact", 
        name: this.state.name,
        title: this.state.title,
        email: this.state.email,
        buttonClicked: this.state.buttonClicked,
      })
    })
      .then(() => {
        this.setState({
          showThanks: true,
          name: "",
          title: "",
          email: "",
        });
      })
      .catch(error => {
        alert(error)
      });

    e.preventDefault();
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value, errors: {} });

  render() {
    let modalBody;
    if (this.state.showThanks) {
      modalBody = (
        <div>
          <Modal.Header closeButton>
            <Modal.Title style={{fontFamily: `Raleway`}}>Thank You</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            We'll be in touch when it's time to get started.
          </Modal.Body>
        </div>
      )
    } else {
      modalBody = (
        <Form style={{marginBottom: 0}} data-netlify="true" data-netlify-honeypot="bot-field" name="contact" onSubmit={this.handleSubmit} netlify>
          <Modal.Header closeButton>
            <Modal.Title style={{fontFamily: `Raleway`}}>{this.state.buttonClicked}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <Form.Group controlId="name">
                <Form.Control type="text" placeholder="name" name="name" value={this.state.name} novalidate onChange={this.handleChange} className={this.state.errors.name && ("is-invalid")}/>
                {this.state.errors.name && (
                  <p className="help text-danger">{this.state.errors.name}</p>
                )}
              </Form.Group>
              <Form.Group controlId="title">
                <Form.Control type="text" placeholder="title" name="title" value={this.state.title} novalidate onChange={this.handleChange} className={this.state.errors.title && ("is-invalid")}/>
                {this.state.errors.title && (
                  <p className="help text-danger">{this.state.errors.title}</p>
                )}
              </Form.Group>
              <Form.Group controlId="email" style={{marginBottom: 0}} >
                <Form.Control type="email" placeholder="email" name="email" value={this.state.email} novalidate onChange={this.handleChange} className={this.state.errors.email && ("is-invalid")}/>
                {this.state.errors.email && (
                  <p className="help text-danger">{this.state.errors.email}</p>
                )}
              </Form.Group>
              <input type="hidden" name="form-name" value="contact" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" style={{backgroundColor: this.state.buttonColor, borderColor: this.state.buttonColor,}} onClick={this.handleSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      );
    }

    return (
    <StaticQuery
      query={graphql`
      query {
        reachCustomersImg: file(relativePath: { eq: "main.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        integrationsImg: file(relativePath: { eq: "demo-screenshot.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        ivrDeflectionImg: file(relativePath: { eq: "IVR-deflection.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        livechatImg: file(relativePath: { eq: "omnichannel.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        efficiencyImg: file(relativePath: { eq: "development.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
      render={data => (
        <Layout modalHandler={this.toggleModal}>
          <SEO title="IVR Service Provider, Web Chat, Social Messaging" description="Messaging is 8x cheaper than phone sales and support. Use Babblestack IVR to acquire, engage and convert on your website and social channels. Eliminate call waiting forever."/>
          <form name="contact" netlify="" netlify-honeypot="bot-field" data-netlify="true" hidden>
            <input type="text" name="name" />
            <input type="text" name="title" />
            <input type="email" name="email" />
            <input type="text" name="buttonClicked" />
          </form>


          <Modal 
            show={this.state.show} 
            onHide={this.handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            {modalBody}
          </Modal>

          <ReactFullpage
            navigation={true}
            anchors={['main', 'ivr-deflection', 'efficiency', 'integrations', 'livechat']}
            render={({ state, fullpageApi }) => {
              return (
                <ReactFullpage.Wrapper>
                  <div className="section">
                    <PageMain
                      pageTitle={"Acquire, engage & serve customers with Live Chat, IVR and Social Messaging"}
                      pageDesc={[
                        "Access the conversational commerce platform built for growing businesses"
                      ]}
                      pageNote={[
                        ""
                      ]}
                      pageImg={data.reachCustomersImg.childImageSharp.fluid}
                      pageDir="right"
                      showFooter={false}
                      sizeLeft="6"
                      sizeRight="6"
                      isFirst={true}
                      pageButton={(
                        <ButtonSignup />
                      )}
                    />
                  </div>
                  <div className="section">
                    <PageMain
                      pageTitle={"Implement IVR Deflection, boost customer satisfaction"}
                      pageDesc={[
                        'Give customers an IVR option to divert their voice call to Messaging instead of waiting on hold',
                        'Customers can "Press 1 to chat" triggering an SMS to be sent to the customer with an Agent chat link',
                        'Customers can then continue the interaction using Facebook, SMS, Live Chat, your Mobile App <b>+ more</b>'
                      ]}
                      pageNote={[
                        "",
                        "",
                        ""
                      ]}
                      pageImg={data.ivrDeflectionImg.childImageSharp.fluid}
                      pageDir="right"
                      showFooter={false}
                      pageButton={(
                        <ButtonDemo onClick={this.toggleModal} />
                      )}
                    />
                  </div>
                  <div className="section">
                    <PageMain
                      pageTitle={"Serve customers in the channels they love, while reducing costs"}
                      pageDesc={[
                        "54% of consumers prefer to contact brands via Messaging, compared with traditional channels such as Phone or Email",
                        "Our conversational commerce platform has helped businesses reduce customer service costs by $10k to $100k+",
                        "Babblestack IVR Deflection drives customer calls to Messaging Agents, <b>saving $4.86 per interaction</b>"
                      ]}
                      pageNote={[
                        "",
                        "",
                        ""
                      ]}
                      pageImg={data.efficiencyImg.childImageSharp.fluid}
                      pageDir="right"
                      showFooter={false}
                      pageButton={(
                        <ButtonSignup />
                      )}
                    />
                  </div>
                  <div className="section">
                    <PageMain
                      pageTitle={"Use one interface to reach customers where they are"}
                      pageDesc={[
                        "Manage and respond to messages from Facebook, WeChat, WhatsApp, Viber <b>+ more</b>",
                        "Send messages to customers from one unified agent portal: Slack, Zendesk, Email <b>+ more</b>",
                        "Send and receive rich messages: Payments, Documents, Images"
                      ]}
                      pageNote={[
                        "",
                        "",
                        ""
                      ]}
                      pageImg={data.integrationsImg.childImageSharp.fluid}
                      pageDir="right"
                      showFooter={false}
                      pageButton={(
                        <ButtonDemo onClick={this.toggleModal} />
                      )}
                    />
                  </div>
                  <div className="section">
                    <PageMain
                      pageTitle={"Engage and convert customers with Babblestack Live Chat"}
                      pageDesc={[
                        "Visitors who engage with Live Chat are 82% more likely to convert",
                        "Add Babblestack Live Chat to your website in 3 minutes or less",
                        "Leverage our AI & chatbot platform integrations to automate customer service and lead qualification"
                      ]}
                      pageNote={[
                        "",
                        "",
                        ""
                      ]}
                      pageImg={data.livechatImg.childImageSharp.fluid}
                      pageDir="right"
                      showFooter={true}
                      pageButton={(
                        <ButtonSignup />
                      )}
                    />
                  </div>

                </ReactFullpage.Wrapper>
              );
            }}
          />
        </Layout>
      )}
    />
    )
  }
}
