import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import Footer from "./footer"

function PageMain ({ pageTitle, pageDesc, pageImg, pageNote, pageDir, showFooter, pageButton, sizeLeft="6", sizeRight="6", isFirst }) {
  let desc;
  let note;
  let mainBody;
  let footer;
  if (pageDesc.length > 1) {
    let list = [];
    let noteList = [];
    let noteCount = 1;
    for (let i = 0; i < pageDesc.length; ++i) {
      if (pageNote[i] !== "") {
        list.push(
          <li>{pageDesc[i]}<sup>{noteCount}</sup></li>
        )
        noteList.push(<li className="main-notes-font">{pageNote[i]}</li>)
        ++noteCount;
      } else {
        list.push(
          <li><div dangerouslySetInnerHTML={{__html: pageDesc[i]}} /></li>
        )
      }
    }
    desc = (
      <ul style={{marginLeft: `1rem`}}>
        {list}
      </ul>
    )
    note = (
      <ol style={{marginLeft: `1rem`}}>
        {noteList}
      </ol>
    )
  } else {
    desc = pageDesc[0];
  }
  let pageDescBox = (isFirst ? (
    <p className="desc-small" >
      {desc}
    </p>
  ) : (
      <p style={{ maxWidth: `100%` }}>
        {desc}
      </p>
  ));

  if (pageDir === "right") {
    mainBody = (
      <Row>
        <Col lg={sizeLeft} className="d-flex align-items-center">
          <Container>
            <h1 className="main-headings">{pageTitle}</h1>
            {pageDescBox}
            <p>
              {pageButton}
            </p>
            <p className="main-notes">
              {note}
            </p>
          </Container>
        </Col>
        <Col lg={sizeRight}>
          <Img fluid={pageImg} fadeIn={false} critical={true}/>
        </Col>
      </Row>
    )
  } else {
    mainBody = (
      <Row>
        <Col lg="6">
          <Img fluid={pageImg} fadeIn={false} critical={true}/>
        </Col>
        <Col lg="6" className="d-flex align-items-center">
          <Container>
            <h1 className="main-headings">{pageTitle}</h1>
            <p>
              {desc}
            </p>
            <p>
              {pageButton}
            </p>
            <p className="main-notes">
              {note}
            </p>
          </Container>
        </Col>
      </Row>
    )
  }
  if (showFooter) {
    footer = <Footer />
  } else {
    footer = "";
  }

  return (
    <div>
      {mainBody}
      {footer}
    </div>
  )
}

PageMain.propTypes = {
  pageTitle: PropTypes.string,
}

PageMain.defaultProps = {
  pageTitle: ``,
}

export default PageMain
